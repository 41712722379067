@-moz-keyframes spin { 
    100% { -moz-transform: rotate(360deg); } 
}
@-webkit-keyframes spin { 
    100% { -webkit-transform: rotate(360deg); } 
}
@keyframes spin { 
    100% { 
        -webkit-transform: rotate(360deg); 
        transform:rotate(360deg); 
    } 
}

img.lang-tab-icon {
    display: inline-block;
    margin-right: 6px;
    width: 32px;
    height: 32px;
}


/***************************** OVERWRITES VENDORS *********************************/	



/***************************** EXTENDING VENDORS *********************************/	
hr.w-25, hr.w-50, hr.w-75 {
    margin: 1rem auto;
}

@media (min-width: 576px) {
	.position-absolute {
		position: absolute !important;
	}
}
@media (min-width: 768px) {
	.position-absolute-sm {
		position: absolute !important;
	}
}
@media (min-width: 992px) {
	.position-absolute-lg {
		position: absolute !important;
	}
	.p-lg-0 {
		padding: 0 !important;
	}
	.p-lg-1 {
		padding: 0.25rem !important;
	}
	.p-lg-2 {
		padding: 0.5rem !important;
	}
	.p-lg-3 {
		padding: 1rem !important;
	}
	.p-lg-4 {
		padding: 1.5rem !important;
	}
	.p-lg-5 {
		padding: 3rem !important;
	}
	.m-lg-0 {
		margin: 0 !important;
	}

	.px-lg-3 {
		padding-left: 1rem !important;
		padding-right: 1rem !important;
	}



	.m-lg-1 {
		margin: 0.25rem !important;
	}
	.m-lg-3 {
		margin: 1rem !important;
	}
	.m-lg-4 {
		margin: 1.5rem !important;
	}
	.m-lg-5 {
		margin: 3rem !important;
	}

	.ms-lg-2 {
		margin-left: 0.5rem !important;
	}



	.pb-lg-5 {
		padding-bottom: 3rem !important;
	}
	
}
@media (min-width: 1200px) {
	.position-absolute-xl {
		position: absolute !important;
	}
}
@media (min-width: 1400px) {
	.position-absolute-xxl {
		position: absolute !important;
	}
	.m-xxl-0 {
		margin: 0 !important;
	}
	.p-xxl-0 {
		padding: 0 !important;
	}
}
/* FULL HD */
@media (min-width: 1900px) {
	.col-fhd-auto {
		flex: 0 0 auto;
		width: auto;
	}
	.col-fhd-1 {
		flex: 0 0 auto;
		width: 8.33333333%;
	}
	.col-fhd-2 {
		flex: 0 0 auto;
		width: 16.66666667%;
	}
	.col-fhd-3 {
		flex: 0 0 auto;
		width: 25%;
	}
	.col-fhd-4 {
		flex: 0 0 auto;
		width: 33.33333333%;
	}
	.col-fhd-5 {
		flex: 0 0 auto;
		width: 41.66666667%;
	}
	.col-fhd-6 {
		flex: 0 0 auto;
		width: 50%;
	}
	.col-fhd-7 {
		flex: 0 0 auto;
		width: 58.33333333%;
	}
	.col-fhd-8 {
		flex: 0 0 auto;
		width: 66.66666667%;
	}
	.col-fhd-9 {
		flex: 0 0 auto;
		width: 75%;
	}
	.col-fhd-10 {
		flex: 0 0 auto;
		width: 83.33333333%;
	}
	.col-fhd-11 {
		flex: 0 0 auto;
		width: 91.66666667%;
	}
	.col-fhd-12 {
		flex: 0 0 auto;
		width: 100%;
	}
}
/* QUAD HD */
@media (min-width: 2500px) {
	.col-qhd-auto {
		flex: 0 0 auto;
		width: auto;
	}
	.col-qhd-1 {
		flex: 0 0 auto;
		width: 8.33333333%;
	}
	.col-qhd-2 {
		flex: 0 0 auto;
		width: 16.66666667%;
	}
	.col-qhd-3 {
		flex: 0 0 auto;
		width: 25%;
	}
	.col-qhd-4 {
		flex: 0 0 auto;
		width: 33.33333333%;
	}
	.col-qhd-5 {
		flex: 0 0 auto;
		width: 41.66666667%;
	}
	.col-qhd-6 {
		flex: 0 0 auto;
		width: 50%;
	}
	.col-qhd-7 {
		flex: 0 0 auto;
		width: 58.33333333%;
	}
	.col-qhd-8 {
		flex: 0 0 auto;
		width: 66.66666667%;
	}
	.col-qhd-9 {
		flex: 0 0 auto;
		width: 75%;
	}
	.col-qhd-10 {
		flex: 0 0 auto;
		width: 83.33333333%;
	}
	.col-qhd-11 {
		flex: 0 0 auto;
		width: 91.66666667%;
	}
	.col-qhd-12 {
		flex: 0 0 auto;
		width: 100%;
	}
}
/* 4K */
@media (min-width: 3800px) {
	.col-4k-auto {
		flex: 0 0 auto;
		width: auto;
	}
	.col-4k-1 {
		flex: 0 0 auto;
		width: 8.33333333%;
	}
	.col-4k-2 {
		flex: 0 0 auto;
		width: 16.66666667%;
	}
	.col-4k-3 {
		flex: 0 0 auto;
		width: 25%;
	}
	.col-4k-4 {
		flex: 0 0 auto;
		width: 33.33333333%;
	}
	.col-4k-5 {
		flex: 0 0 auto;
		width: 41.66666667%;
	}
	.col-4k-6 {
		flex: 0 0 auto;
		width: 50%;
	}
	.col-4k-7 {
		flex: 0 0 auto;
		width: 58.33333333%;
	}
	.col-4k-8 {
		flex: 0 0 auto;
		width: 66.66666667%;
	}
	.col-4k-9 {
		flex: 0 0 auto;
		width: 75%;
	}
	.col-4k-10 {
		flex: 0 0 auto;
		width: 83.33333333%;
	}
	.col-4k-11 {
		flex: 0 0 auto;
		width: 91.66666667%;
	}
	.col-4k-12 {
		flex: 0 0 auto;
		width: 100%;
	}
}





/* MEDIA EXAMPLES BOOTSTRAP 5 */


@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 992px) {
}
@media (min-width: 1200px) {
}
@media (min-width: 1400px) {
}


@media (max-width: 575.98px) {
}
@media (max-width: 767.98px) {
}
@media (max-width: 991.98px) {
}
@media (max-width: 1199.98px) {
}
@media (max-width: 1399.98px) {
}